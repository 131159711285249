import { api } from '../../app/api/baseApi';

export const timelineApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCount: builder.mutation<any, { realm?: string; organizationId?: string }>({
            query: ({ realm, organizationId }) => ({
                url: 'alarm/v1/count',
                method: 'POST',
                headers: {
                    ...(realm ? { 'x-realm': realm } : {}),
                    ...(organizationId ? { organizationId: organizationId } : {}),
                },
                body: {
                    ...(organizationId ? { organizationId } : {}),
                },
            }),
        }),
        getTimelineDetailsById: builder.query<any, any>({
            query: (id) => ({
                url: `alarm/v1/timeline/${id}`,
                method: 'GET',
            }),
            providesTags: ['TimelineById'],
        }),
        getTimelineDetailsByIdWithRealm: builder.query<any, any>({
            query: ({ id, realmPrefix }) => ({
                url: `alarm/v1/timeline/${id}`,
                method: 'GET',
                headers: realmPrefix && { 'x-realm': realmPrefix },
            }),
            providesTags: ['TimelineById'],
        }),
        updateAcknowledge: builder.mutation<any, any>({
            query: (body) => ({
                url: `alarm/v1/timeline/acknowledge`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['TimelineById'] : []),
        }),
        getRealmTimeline: builder.query({
            query: ({ body, realmPrefix }) => ({
                url: `alarm/v1/timeline/filter`,
                method: 'POST',
                body,
                headers: realmPrefix && { 'x-realm': realmPrefix },
            }),
            keepUnusedDataFor: 0.0001,
            providesTags: ['RealmTimelineTags'],
        }),
        getRealmTimelineById: builder.query({
            query: ({ timelineid, realmPrefix }) => ({
                url: `alarm/v1/timeline/${timelineid}`,
                method: 'GET',
                headers: realmPrefix && { 'x-realm': realmPrefix },
            }),
            providesTags: ['RealmTimelineTags'],
        }),
        ackTimeline: builder.mutation({
            query: ({ id, realmPrefix, body }) => {
                return {
                    url: `/alarm/v1/timeline/acknowledge`,
                    method: 'PATCH',
                    body,
                    headers: {
                        'x-realm': realmPrefix,
                        organizationId: id,
                    },
                };
            },
            invalidatesTags: ['RealmTimelineTags'],
        }),

        clearTimeline: builder.mutation({
            query: ({ timelineId, realmPrefix, organizationId, body }) => {
                return {
                    url: `/alarm/v1/timeline/clear/${timelineId}`,
                    method: 'PATCH',
                    body,
                    headers: { 'x-realm': realmPrefix, organizationId: organizationId },
                };
            },
        }),

        getTimelineAllStatus: builder.query<any, any>({
            query: (params: any) => ({
                url: `alarm/v1/timeline/status`,
                method: 'GET',
                ...(params && { params }),
            }),
        }),
        downloadTimeline: builder.mutation({
            query: ({ body, params }) => ({
                url: `alarm/v1/timeline/download`,
                method: 'POST',
                body,
                params,
            }),
        }),

        createSnooze: builder.mutation({
            query: (params) => ({
                url: `alarm/v1/timeline/snooze/device`,
                method: 'POST',
                params,
            }),
            invalidatesTags: (result, error) => (!error ? ['SnoozeByDeviceId', 'DeviceById'] : []),
        }),
        getSnoozeByDeviceId: builder.query({
            query: (params) => ({
                url: `alarm/v1/timeline/snooze`,
                method: 'GET',
                params,
            }),
            providesTags: ['SnoozeByDeviceId'],
        }),
        timelineComment: builder.mutation({
            query: ({ body, realmPrefix }) => ({
                url: `dm/v1/device-comments/device`,
                method: 'POST',
                body,
                headers: realmPrefix && { 'x-realm': realmPrefix },
            }),
            invalidatesTags: ['TimelineComment', 'RealmTimelineTags'],
        }),
        getTimelineComments: builder.query<any, any>({
            query: ({ timelineId, realmPrefix }) => ({
                url: `dm/v1/device-comments/timeline/${timelineId}`,
                method: 'GET',
                headers: realmPrefix && { 'x-realm': realmPrefix },
            }),
            providesTags: ['TimelineComment'],
        }),

        getTimelineTrends: builder.query<any, any>({
            query: ({ params, deviceId }: any) => ({
                url: `dm/v1/devices/${deviceId}/timeline/trends`,
                method: 'GET',
                ...(params && { params }),
            }),
        }),
        getAllComments: builder.query<any, any>({
            query: ({ serialNumber, page, size, realmPrefix }: any) => ({
                url: `dm/v1/device-comments/${serialNumber}?category=ALARMS&page=${page}&size=${size}`,
                method: 'GET',
                headers: realmPrefix && { 'x-realm': realmPrefix },
            }),
            providesTags: ['TimelineComment'],
        }),
    }),
});

export const {
    useGetCountMutation,
    useGetTimelineDetailsByIdQuery,
    useGetTimelineDetailsByIdWithRealmQuery,
    useUpdateAcknowledgeMutation,
    useGetRealmTimelineQuery,
    useGetRealmTimelineByIdQuery,
    useLazyGetRealmTimelineByIdQuery,
    useAckTimelineMutation,
    useClearTimelineMutation,
    useGetTimelineAllStatusQuery,
    useDownloadTimelineMutation,
    useCreateSnoozeMutation,
    useGetSnoozeByDeviceIdQuery,
    useTimelineCommentMutation,
    useGetTimelineCommentsQuery,
    useGetTimelineTrendsQuery,
    useGetAllCommentsQuery,
} = timelineApi;
