export const USER_SETTINGS = 'user_settings';
export const LOCAL_USER_DATA = 'user_data';
export const REMEMBER_ME_DATA = 'remember_me_data';
export const REMEMBER_BIOMETRICS_DATA = 'remember_biometrics_data';
export const HAS_BIOMETRICS = 'has_biometrics';
export const HAS_BEEN_ASKED_FOR_BIOMETRICS = 'has_been_asked_for_biometrics';
export const HAS_LOGGED_OUT = 'has_logged_out';
export const CST_EMAIL = 'BLSupport@eaton.com';
export const CST_PHONE_NUMBER = '1-877-ETN-CARE';
export const PACKAGE_NAME = 'com.eaton.brightlayerrmd';

export const MIN_DRAWER_WIDTH = 72;
export const MAX_DRAWER_WIDTH = 600;
export const DEFAULT_DRAWER_WIDTH = 360;
export const MAX_POWER_ON_DELAY = 255;
export const ORG_NAME_LIMIT = 50;

export const SHORT_GROUP_NAME_LENGTH_LIMIT = 6;
export const GROUP_NAME_LENGTH_LIMIT = 24;
export const LOAD_NAME_LENGTH_LIMIT = 50;

export const TAGS_LIMIT = 6;
export const TAG_LIMIT = 16;
export const ACCOUNT_ID_LIMIT = 32;
export const DEVICE_FIELDS_LIMIT: any = {
    productId: 6,
    serialNumber: 18,
    description: 256,
    deviceName: 32,
    guid: 36,
};

export const DEVICE_CHIPS: any = {
    deviceStatus: 'Status',
    connectionStatus: 'Status',
    groups: 'Groups',
    tags: 'Tag(s)',
    deviceName: 'Device Name',
    parentId: 'Gateway',
    modelCategory: 'Device Types',
};
export const USER_DETAILS_LIMIT = 50;
export const PHONE_NUMBER_LIMIT = 10;
export const DELETE_KEY = 6;
export const guidRegex = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i;
export const NUMBER_REGEX = /^\d+$/;
export const DECIMAL_REGEX = /^\d*\.?\d*$/;
export const ROLE_NAME_LIMIT = 20;
export const ROLE_DESCRIPTION_LIMIT = 256;

export const PXDBP = 'pxdbp';
export const PXDBL = 'pxdbl';
export const PXIG = 'pxig';
export const BLG300 = 'blg300';
export const BLUEPRINT_ORGANIZATION_NAME = 'BLI Pre Production Test';
export const VENDOR_NAME = 'sierra';
export enum MasterDataKey {
    STARFISH = 'starfishchilddevices',
    DEVICETYPES = 'devicetypes',
}
export const MASTERDATA_DEVICE_TYPE = {
    gateway: 'gateway',
    child: 'child',
};

export const UI_METATADATA_DEVICE_TYPE = {
    gateway: 'Gateway',
    child: 'Child',
};

export const FORM_ELEMENT = {
    deviceType: 'deviceType',
};

export const VENDOR_TYPE_NAME = 'Starfish';

export const DEVICE_CATEGORY = {
    gateway: 'gateway',
    child: 'child',
    standalone: 'standalone',
};

export const GATEWAY_VENDOR = {
    starfish: 'starfish',
    sierra: 'sierra',
    dashboard: 'dashboard',
    generic: 'generic',
};

export const TIME_BTN = [
    {
        btnName: '24 Hours',
        btnValue: 24,
    },
    {
        btnName: '15 Days',
        btnValue: 360,
    },
    {
        btnName: '31 Days',
        btnValue: 744,
    },
];

export const ACKNOWLEDGE_TIMELINE_LIMIT = 256;
export const TAG_NAME_LIMIT = 16;
export const GROUP_NAME_LIMIT = 24;
export const GROUP_SHORTNAME_LIMIT = 8;
export const DEFAULT_HIERARCHY_SIZE = 25;
export const WIDGET_NAME_LIMIT = 32;
export const CHANNEL_NAME_LIMIT = 32;
export const WIDGET_DESCRIPTION_LIMIT = 128;
export const ENTITLEMENT_NAME_LIMIT = 32;
export const ENTITLEMENT_DESCRIPTION_LIMIT = 256;
export const PROPERTY_WIDGET_NAME_DESCRIPTION = 256;
export { BRANDING_COLOR_PALLETE } from './palette';

export const GRID_COLS = 50;
export const GRID_ROWS = 50;
export { mapStyles } from './mapThemeStyle';
export const seriesColors: string[] = [
    '#0088F2',
    '#F0CB2F',
    '#F47721',
    '#39B620',
    '#983FEF',
    '#004B9E',
    '#87C6D4',
    '#9C0E33',
    '#008A7E',
    '#774E08',
];

// Names of frequently used colors from : https://www.favocolor.com/hex/424e544d

export const AuroMetalSaurus = '#727E84';
export const MadderLake = '#CA3C3D';
export const OceanBoatBlue = '#007BC1';
export const Marigold = '#F0AA1F';
export const LightBlue = '#B3D7EC';
export const VividTangelo = '#F47721';
export const White = '#FFFFFF';
export const BrightGray = '#EEF0F0';
export const OuterSpace = '#424E54';

export const CHANNEL_PROFILE_NAME_LIMIT = 24;
export const CHANNEL_PROFILE_DESCRIPTION_LIMIT = 200;
export const MANUFACTURER = 'Eaton';
export const LOCATION_TYPE = 'Organization';

// Common
export const CSV = 'CSV';
export const SMS = 'SMS';
export const EMAIL = 'EMAIL';
export const PHONECALL = 'PHONECALL';

export const CRITICAL = 'CRITICAL';
export const WARNING = 'WARNING';
export const MINOR = 'MINOR';
export const MAJOR = 'MAJOR';
export const INFORMATIONAL = 'INFORMATIONAL';

export const FILTER_ALARM_ACTIVE = 'Alarm Active';
export const FILTER_LOW_WARNING = 'Low Warning';
export const FILTER_WARNING_ACTIVE = 'Warning Active';

export const TIME_FORMAT_LT = 'LT';
export const DATE_FORMAT_MMDDYY = 'MM/DD/YY';
export const DATE_FORMAT_MMDDYYYY = 'MM/DD/YYYY';
export const CADENCE = [0, 1, 2, 3];
